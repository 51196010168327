<template>
  <main>
    <section class="fullscreen">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-4">
            <div class="card card-with-icon text-center h-100">
              <div class="card-body d-flex flex-column justify-content-center align-items-center">
                <i class="fas fa-disease text-primary"></i> 
                <h5 class="card-title">Krankheit</h5>
                <p class="card-text">Lorem Ipsum dolor sit amet</p>
                <router-link to="/krankheit" class="btn btn-sm btn-outline-primary stretched-link">Übersicht</router-link>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card card-with-icon text-center h-100">
              <div class="card-body d-flex flex-column justify-content-center align-items-center">
                <i class="fas fa-bacteria text-primary"></i> 
                <h5 class="card-title">Erreger</h5>
                <p class="card-text">Lorem Ipsum dolor sit amet, consetetur sadipscing elitr </p>
                <router-link to="/erreger" class="btn btn-sm btn-outline-primary stretched-link">Übersicht</router-link>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card card-with-icon text-center h-100">
              <div class="card-body d-flex flex-column justify-content-center align-items-center">
                <i class="fas fa-pills fa-4x mb-3 text-primary"></i> 
                <h5 class="card-title">Medikament</h5>
                <p class="card-text">Lorem Ipsum dolor sit amet</p>
                <router-link to="/medikament" class="btn btn-sm btn-outline-primary stretched-link">Übersicht</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
    
}
</script>