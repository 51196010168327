<template>
  <main>
    <div class="container">
      <section>
        <div class="row justify-content-center">
          <div class="col-xl-10">
            <div class="card">
              <div class="card-header">
                <h1 class="h4 mt-2">Login</h1>
              </div>
              <div class="card-body">
                <div class="container">
                  <div class="row">
                    <form id="login" @submit.prevent="login">    
                      <label for="name">E-Mail</label>
                      <input ref="email" placeholder="Email">
                      <label for="name">Password</label>
                      <input ref="password" type="password" placeholder="Password">
                      <input type="submit" value="Anmelden">
                    </form>
                  </div>
                </div>
              </div>
              <p id="login-error"> {{error}} </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
  function inputNotEmpty(email, password) {
    if(email.length == 0 || password.length == 0) {
      return [false , "Alle Felder müssen ausgefüllt sein"];
    } else {
      return [true];
    }
  }

  export default {
    name: 'Login',
    data() {
      return {
        error: ''
      }
    },
    methods: {
      login(event) {
        event.preventDefault();
        var returnState = inputNotEmpty(this.$refs.email.value, this.$refs.password.value);
        console.log(returnState);
        if(returnState[0])
          this.$emit("input", this.$refs.email.value, this.$refs.password.value);
        else 
          this.error = returnState[1];
      }
    }
  }
</script>