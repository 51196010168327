<template>
  <div class="modal fade show" id="NewPatientModal" tabindex="-1" aria-labelledby="NewPatientModalLabel" style="display: none;" aria-modal="true" role="dialog">
    <div class="modal-dialog modal-dialog-centered modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          Behandlung fortsetzen?
        </div>
        <div class="modal-footer">
          <a href="/" class="btn btn-sm btn-outline-primary">Neuer Patient</a>
          <a class="btn btn-sm btn-primary" data-dismiss="modal">Fortsetzen</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

</script>