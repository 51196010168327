<template>
  <div v-if=isLogin()>
    <Navigation />
    <Overview />
  </div>
  <div v-else>
    <Login @input="login" />
  </div>
</template>
 
<script>
  // @ is an alias to /src
  import Overview from "@/components/Overview.vue";
  import Navigation from "@/components/Navigation.vue";
  import Login from "@/components/Login.vue";
  import Mixin from "../mixin.js";

  export default {
    mixins: [Mixin],
    components: {
      Overview,
      Navigation,
      Login
    },
    methods: {
      login: function(email="", password="") {
        if(email != "" && password != "") {
          var data = {
            "email": email,
            "password": password
          };

          fetch('https://directus.comkom.de/auth/login', {
            method: 'POST', 
            mode: 'cors', 
            cache: 'no-cache',
            credentials: 'same-origin', 
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', 
            referrerPolicy: 'no-referrer', 
            body: JSON.stringify(data)
          })
          .then(response => {
            if (!response.ok) {
              throw Error(response.statusText);
            }
            return response.json();
          })
          .then(json => {
            var d = new Date();
            d.setTime(d.getTime() + (json.data.expires));
            var expiresOther = "expires="+ d.toUTCString();
            d.setTime(d.getTime() + (json.data.expires * 480));            
            var expiresRefresh = "expires="+ d.toUTCString();
            document.cookie = "directus_api_auth_token" + "=" + json.data.access_token + ";" + expiresOther + ";path=/";
            document.cookie = "directus_api_refresh_token" + "=" + json.data.refresh_token + ";" + expiresRefresh + ";path=/";
            document.cookie = "antibiotika_app_login = true;" + expiresOther + ";path=/";
            location.reload();
          })
          .catch(error => {
            document.getElementById('login-error').textContent = "Die Logindaten stimmen nicht überein";
          });
        }
      }
    },
    created() {
      document.title = "Antibiotika App";
    },
    mounted() {
      this.$nextTick(() => {
        if (document.getElementById('home') != null)
          document.getElementById('home').classList.add('active');
      });
    }
  };
</script>
