<template>
  <nav class="navbar navbar-logo navbar-expand navbar-light bg-white">
    <router-link to="/" class="navbar-brand font-weight-bold" id="home">
      <i class="fas fa-book-medical"></i> Antibiotika-App
    </router-link>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item">
        <a class="nav-link modal-toggle" data-toggle="modal" data-target="#NewPatientModal">
          <i class="fas fa-user-plus"><span class="sr-only">Neuer Patient</span></i>
        </a>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarScrollingDropdown" role="button" data-toggle="dropdown" aria-expanded="false">
          <i class="fas fa-ellipsis-v"><span class="sr-only">Menu</span></i>
        </a>
        <ul id="hidden-menu" class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarScrollingDropdown">
          <li><router-link to="/allgemeine-hinweise" class="dropdown-item"> Allgemeine Hinweise </router-link></li>
          <li><router-link to="/dosierungsanpassungen" class="dropdown-item"> Dosierungsanpassungen </router-link></li>
          <li><a class="dropdown-item" href="#">Feedback</a></li>
          <li><a v-on:click="logout" class="dropdown-item text-danger" href="#">Abmelden</a></li>
        </ul>
      </li>
    </ul>
  </nav>
  <nav class="navbar navbar-navigation navbar-expand navbar-light bg-white">
    <ul class="navbar-nav mx-auto">
      
      <li id="disease" class="nav-item">
        <router-link to="/krankheit" class="nav-link text-center">
          <i class="fas fa-disease d-block mb-1"></i> Krankheit
        </router-link>
      </li>
      <li id="pathogen" class="nav-item">
        <router-link to="/erreger" class="nav-link text-center">
          <i class="fas fa-bacteria d-block mb-1"></i> Erreger
        </router-link>
      </li>
      <li id="medicament" class="nav-item">
        <router-link to="/medikament" class="nav-link text-center">
          <i class="fas fa-pills d-block mb-1"></i> Medikament
        </router-link>
      </li>
    </ul>
  </nav>
  <NewPatientModal />
</template>

<script>
  import NewPatientModal from "./NewPatientModal";

  export default {
    components: {
      NewPatientModal
    },
    methods: {
      logout() {
        var data = {
          "refresh_token": document.cookie.match(new RegExp('(^| )' + 'directus_api_refresh_token' + '=([^;]+)'))[2]
        };
        fetch('https://directus.comkom.de/auth/logout', {
          method: 'POST', 
          mode: 'cors', 
          cache: 'no-cache',
          credentials: 'same-origin', 
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow', 
          referrerPolicy: 'no-referrer', 
          body: JSON.stringify(data)
        })
        .then(response => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          document.cookie = 'antibiotika_app_login=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'directus_api_auth_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          document.cookie = 'directus_api_refresh_token=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          location.reload();
        });              
      },
    }
  }
</script>