import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import styles from "./styles/scss/style.scss"
import bootstrap from "bootstrap";

createApp(App)
  // .use(store)
  .use(router)
  .use(styles)
  .use(bootstrap)
  .mount('#app')
