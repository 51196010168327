import { createRouter, createWebHistory} from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/allgemeine-hinweise",
    name: "Allgemeine Hinweise",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/GeneralInformation.vue")
  },
  {
    path: "/dosierungsanpassungen",
    name: "Dosierungsanpassungen",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Dosage.vue")
  },
  {
    path: "/krankheit",
    name: "Krankheit",
    component: () =>
      import(/* webpackChunkName: "diseases" */ "../views/PythianLocation.vue"),
  },
  {
    path: "/krankheit/:pythian_location",
    name: "diseases",
    props: true,
    component: () =>
      import(/* webpackChunkName: "diseases" */ "../views/Diseases.vue"),
  },
  {
    path: "/krankheit/:pythian_location/:name",
    name: "singlediseas",
    props: true,
    component: () =>
      import(/* webpackChunkName: "singlediseases" */ "../views/SingleDisease.vue")
  },
  {
    path: "/erreger",
    name: "Erreger",
    component: () =>
      import(/* webpackChunkName: "diseases" */ "../views/Pathogen.vue"),
  },
  {
    path: "/erreger/:name",
    name: "singledpathogen",
    props: true,
    component: () =>
      import(/* webpackChunkName: "singlediseases" */ "../views/SinglePathogen.vue")
  },
  {
    path: "/medikament",
    name: "Medikament",
    component: () =>
      import(/* webpackChunkName: "diseases" */ "../views/Medicament.vue"),
  },
  {
    path: "/medikament/:name",
    name: "singledmedicament",
    props: true,
    component: () =>
      import(/* webpackChunkName: "singlediseases" */ "../views/SingleMedicament.vue")
  }  
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
