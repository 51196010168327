function checkStatus() {
  var status = true;
  if(document.cookie.match(new RegExp('(^| )' + 'antibiotika_app_login' + '=([^;]+)'))) {
  } else if(document.cookie.match(new RegExp('(^| )' + 'directus_api_refresh_token' + '=([^;]+)'))){
    var data = {
      "refresh_token": document.cookie.match(new RegExp('(^| )' + 'directus_api_refresh_token' + '=([^;]+)'))[2]
    };
    fetch('https://directus.comkom.de/auth/refresh', {
      method: 'POST', 
      mode: 'cors', 
      cache: 'no-cache',
      credentials: 'same-origin', 
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow', 
      referrerPolicy: 'no-referrer', 
      body: JSON.stringify(data)
    })
    .then(response => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then(json => {
      var d = new Date();
      d.setTime(d.getTime() + (json.data.expires));
      var expiresOther = "expires="+ d.toUTCString();
      d.setTime(d.getTime() + (json.data.expires * 480));            
      var expiresRefresh = "expires="+ d.toUTCString();
      document.cookie = "directus_api_auth_token" + "=" + json.data.access_token + ";" + expiresOther + ";path=/";
      document.cookie = "directus_api_refresh_token" + "=" + json.data.refresh_token + ";" + expiresRefresh + ";path=/";
      document.cookie = "antibiotika_app_login = true;" + expiresOther + ";path=/";
      location.reload();
    })
    .catch(err => console.log(err));
  } else {
    status = false;
    if(window.location.pathname != "/")
      window.location.replace("/");
  }
  return status;    
}

export default {
  methods: {
    isLogin: function() {
      return checkStatus();
    }
  }
}
